import { useCallback } from 'react';

import { Paper } from 'uikit/Paper';
import { Box } from 'uikitv2/Box';
// import { Counter } from 'uikitv2/Counter';
import { Divider } from 'uikitv2/Divider';
import { BilletValue } from 'components/BilletValue';
import { AdderBtn } from 'components/AdderBtn';
import classes from './Billet.module.scss';
import { BilletProps } from './types';

export const Billet = ({
  data,
  value,
  isSelected,
  loading,
  onAdd: onAddProp,
  onDelete: onDeleteProp,
  isCanUpdate,
  isShowCounter,
}: BilletProps) => {
  const { id, title, options } = data;
  const onAdd = useCallback(() => onAddProp?.(id?.value), [onAddProp, id]);
  const onDelete = useCallback(() => onDeleteProp?.(id?.value), [onDeleteProp, id]);

  return (
    <Paper className={classes.wrapper}>
      <Box className={classes.title} justifyContent="space-between" alignItems="center">
        <BilletValue
          className={classes.billetValue}
          {...title}
        />
        {!!(onAdd || onDelete) && isCanUpdate && (
          <AdderBtn
            disabled={loading}
            onAdd={onAdd}
            onDelete={onDelete}
            isAdded={isSelected}
            dataTestId={`offer-${id.value}-modal`}
            variantDeleteBtn="obscure"
            size="small"
          />
        )}
      </Box>
      <Divider className={classes.divider} />
      <Box className={classes.content}>
        <div className={classes.options}>
          {options.map((item) => (
            <BilletValue
              key={item.label}
              className={classes.option}
              {...item}
            />
          ))}
          {options.length < 7 && options.length > 0 && (
            <>
              {Array.from(Array(7 - options.length).keys()).map((item) => (
                <div key={item} />
              ))}
            </>
          )}
        </div>
        <Box direction="column" justifyContent="flex-end" alignItems="flex-end">
          <BilletValue
            {...id}
          />
        </Box>
      </Box>
    </Paper>
  );
};
