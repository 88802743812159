import { useCallback, useMemo } from 'react';
import { Box } from 'uikit/Box';
import { useContent } from 'lib/features/createOrderV2/hooks/useContent';
import useGetOffer from './useGetOffer';
import { OfferProps } from './types';
import { Left } from './Left';
import { Right } from './Right';
import { Data } from './helpers';
import classes from './Offer.module.scss';

export const Offer = ({
  id, slotsFromQuery, tab, onChangeTab,
}: OfferProps) => {
  const {
    deleteOfferCatched, loading, addOfferCatched, deleteSlotCatched, addSlotCatched,
  } = useContent();
  const { original, formatted, isTee } = useGetOffer(id, slotsFromQuery);
  const { right, left, common } = useMemo(() => formatted || {} as Data, [formatted]);
  const onDeleteSlot = useCallback(() => {
    deleteSlotCatched({
      field: common?.fieldBuildOrderForm, offerId: common?.id,
    });
  }, [deleteSlotCatched, common]);

  const onAddSlot = useCallback((slotId: string) => {
    addSlotCatched({
      field: common?.fieldBuildOrderForm, offerId: common?.id, slotId,
    });
  }, [addSlotCatched, common]);

  const onAddOffer = useCallback(() => {
    addOfferCatched({
      field: common?.fieldBuildOrderForm, value: common?.id, data: original, slots: slotsFromQuery,
    });
  }, [addOfferCatched, common, original, slotsFromQuery]);

  const onDelete = useCallback(() => {
    deleteOfferCatched({ field: common?.fieldBuildOrderForm, value: common?.id });
  }, [deleteOfferCatched, common]);

  return (
    <Box className={classes.content}>
      <Left {...{
        data: left,
        selectedSlots: common?.selectedSlots,
        loading,
        onAddSlot,
        onDeleteSlot,
        isCanUpdateSlot: !isTee && original?.enabled,
        isShowCounter: isTee && common?.isAdded,
        isTee,
        tab,
        onChangeTab,
      }}
      />
      <Right
        {...{
          data: right,
          price: common?.price,
          id,
          loading,
          isAdded: common?.isAdded,
          isShowAddBtn: isTee,
        }}
        onAdd={onAddOffer}
        onDelete={onDelete}
      />
    </Box>
  );
};
