import { useAuth } from 'hooks/auth/useAuth';
import { useSPFiles, UseSPFiles, UseSpFilesProps } from './useSPFiles';
import { useSecretKeeperFiles, UseSecretKeeperFiles, UseSecretKeeperFilesProps } from './useSecretKeeperFiles';

export type UseFiles = UseSPFiles | UseSecretKeeperFiles;
export type UseFilesProps = UseSpFilesProps | UseSecretKeeperFilesProps;

export const useFiles = (props?: UseFilesProps): UseFiles => {
  const { isProviderSPProvider } = useAuth();
  const spFiles = useSPFiles(props);
  const secretKeeperFiles = useSecretKeeperFiles(props);

  return isProviderSPProvider ? spFiles : secretKeeperFiles;
};