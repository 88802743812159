import { File, GetFilesProps } from 'lib/features/spProvider/types';
import { useGetFilesQuery } from 'lib/features/spProvider/api';
import { useAuth } from 'hooks/auth/useAuth';

export interface UseSPFiles {
  files?: File[];
  loading: boolean;
  refetch: () => void;
}

export interface UseSpFilesProps {
  skip?: boolean;
  body?: GetFilesProps | null;
}

export const useSPFiles = (props?: UseSpFilesProps): UseSPFiles => {
  const { skip, body = null } = props || {};
  const { isUserConnected, isProviderSPProvider } = useAuth();
  const response = useGetFilesQuery(
    body,
    { skip: !isUserConnected || !isProviderSPProvider || skip, refetchOnMountOrArgChange: true },
  );

  return {
    files: response.data,
    loading: response.isLoading || response.isFetching,
    refetch: response.refetch,
  };
};