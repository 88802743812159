import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WindowSizeState } from './types';

const initialState: WindowSizeState = {
  width: 0,
  height: 0,
};

export const windowSize = createSlice({
  name: 'windowSize',
  initialState,
  reducers: {
    updateSize(state, action: PayloadAction<{ height: number, width: number }>) {
      const { width, height } = action.payload;
      state.width = width;
      state.height = height;
    },
  },
  selectors: {
    windowSizeSelector: (state) => (state),
    isMobileSelector: (state) => (state.width ? state.width < 1120 : false),
  },
});

export const {
  updateSize,
} = windowSize.actions;

export const {
  windowSizeSelector,
  isMobileSelector,
} = windowSize.selectors;
