import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Compute, Dataset, Engine, FieldsBuildOrderForm, Model,
} from './types';
import {
  addCompute, addDataset, addEngine, addModel, deleteCompute, deleteDataset, deleteEngine, deleteModel, NAME,
} from '.';

const addContentActions = {
  [FieldsBuildOrderForm.model]: addModel,
  [FieldsBuildOrderForm.datasets]: addDataset,
  [FieldsBuildOrderForm.compute]: addCompute,
  [FieldsBuildOrderForm.engine]: addEngine,
};

export const addContent = createAsyncThunk<
  void,
  { value?: Dataset | Model | Engine | Compute | null, field?: FieldsBuildOrderForm }
>(
  `${NAME}/addContent`,
  async ({ value, field }, { dispatch }) => {
    if (!field) return;
    const action = addContentActions[field];
    dispatch(action({ value }));
  },
);

const deleteContentActions = {
  [FieldsBuildOrderForm.model]: deleteModel,
  [FieldsBuildOrderForm.datasets]: deleteDataset,
  [FieldsBuildOrderForm.compute]: deleteCompute,
  [FieldsBuildOrderForm.engine]: deleteEngine,
};

export const deleteContent = createAsyncThunk<
  void,
  { fileId?: string; offerId?: string, field?: FieldsBuildOrderForm }
>(
  `${NAME}/deleteContent`,
  async ({ fileId, offerId, field }, { dispatch }) => {
    if (!field) return;
    const action = deleteContentActions[field];
    dispatch(action({ fileId, offerId }));
  },
);