import { OfferType, ValueOfferSubtype, TeeOfferSubtype } from '@super-protocol/sdk-js';
import {
  Offer, TeeOffer, HardwareInfo,
} from 'generated/types';
import {
  BuildOrderForm, FormContentKey, Slots,
} from 'lib/features/createOrderV2/types';
import {
  getFieldBuildOrderdByOfferType, getSelectedSlot, checkAddContent, getOfferPriceBySlots,
} from 'lib/features/createOrderV2/helpers';
import {
  getTableDate, boolToStr, capitalize,
} from 'utils';
import { getCategoriesByConfiguration, getTeeOfferSubTypeName, getValueOfferSubTypeName } from 'utils/sdk/utils';
import { convertSlotInfo, convertOptionData } from 'utils/slots';
import { LeftProps, Configuration } from './Left/types';

export const fieldsMap = {
  subType: 'Offer Type:',
  id: 'ID:',
  provider: 'Provider:',
  published: 'Published:',
  disabledAfter: 'Disabled After:',
  updated: 'Updated:',
  cancelable: 'Cancelable:',
  categories: 'Category:',
};

export interface Data {
  common: {
    price: {
      type: string;
      sum: string;
    }[];
    restrictions: string[];
    fieldBuildOrderForm?: FormContentKey;
    selectedSlots?: Slots | null;
    id: string;
    isAdded: boolean;
  };
  left: LeftProps;
  right: Record<string, any>;
}

export const offerDataTransform = (
  data: Offer,
  buildOrderForm: BuildOrderForm,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, offerInfo, providerInfo, origins, disabledAfter, slots, configuration,
  } = data;
  const field = getFieldBuildOrderdByOfferType(offerInfo?.subType as ValueOfferSubtype);
  const selectedSlots = getSelectedSlot(buildOrderForm, field, id);
  
  const categories = getCategoriesByConfiguration(configuration, offerInfo.subType as ValueOfferSubtype);
  
  const isAdded = checkAddContent({
    formContent: buildOrderForm,
    field,
    offerId: id,
  });

  const subType =  getValueOfferSubTypeName(offerInfo.subType as ValueOfferSubtype);

  return {
    common: {
      price: getOfferPriceBySlots(selectedSlots, slots),
      restrictions: offerInfo?.restrictions?.offers || [],
      fieldBuildOrderForm: field,
      id,
      selectedSlots,
      isAdded,
    },
    left: {
      name: offerInfo.name,
      about: offerInfo.description,
      restricted: offerInfo.restrictions?.offers || [],
      type: offerInfo.offerType as OfferType,
      slots,
      subType,
    },
    right: {
      id,
      subType,
      categories,
      provider: providerInfo.name,
      published: getTableDate(origins?.createdDate),
      ...(disabledAfter ? { disabledAfter: disabledAfter?.toString() as string } : {}),
      updated: getTableDate(origins?.modifiedDate),
    },
  };
};

export const getTEEConfiguration = (hardwareInfo: HardwareInfo): Configuration => {
  if (!hardwareInfo) return { slots: [], options: [] };
  const { slotInfo, optionInfo } = hardwareInfo || {};
  return {
    slots: convertSlotInfo(slotInfo),
    options: convertOptionData(optionInfo ? [optionInfo] : []),
  };
};

export const teeOfferDataTransform = (
  data: TeeOffer | undefined,
  buildOrderForm?: BuildOrderForm,
  slotsFromQuery?: Slots | null,
): Data | undefined => {
  if (!data) return undefined;

  const {
    id, teeOfferInfo, providerInfo, origins, disabledAfter, slots, options,
  } = data;
  const { hardwareInfo } = teeOfferInfo || {};
  const field = getFieldBuildOrderdByOfferType(teeOfferInfo?.subType as TeeOfferSubtype);

  const isAdded = checkAddContent({
    teeOfferId: id,
    field,
    formContent: buildOrderForm,
    slots: slotsFromQuery,
  });

  const subType =  getTeeOfferSubTypeName(teeOfferInfo?.subType as TeeOfferSubtype);

  return {
    common: {
      price: getOfferPriceBySlots(slotsFromQuery, slots, options),
      restrictions: [],
      id,
      fieldBuildOrderForm: field,
      selectedSlots: slotsFromQuery,
      isAdded,
    },
    left: {
      name: teeOfferInfo.name,
      about: teeOfferInfo.description,
      restricted: [],
      type: OfferType.TeeOffer,
      slots,
      options,
      configuration: getTEEConfiguration(hardwareInfo),
      subType,
    },
    right: {
      id,
      subType,
      provider: providerInfo.name,
      published: getTableDate(origins?.createdDate),
      ...(disabledAfter ? { disabledAfter: disabledAfter?.toString() as string } : {}),
      updated: getTableDate(origins?.modifiedDate),
      cancelable: capitalize(boolToStr(true)),
    },
  };
};
