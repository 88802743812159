import { OfferType } from '@super-protocol/sdk-js';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { getOfferTypeName } from 'utils/sdk/utils';
import { TooltipDeposit } from 'components/TooltipDeposit';
import {
  OfferInfo, ProviderInfo, TeeOfferSlot,
} from 'generated/types';
import { Column } from './types';
import classes from './Restricted.module.scss';

const columnHelper = createColumnHelper<Column>();

export const getColumns = (onClickRow): ColumnDef<Column, any>[] => [
  columnHelper.display({
    id: 'restriction',
    header: 'Restriction',
    size: 100,
    cell: () => 'Must use',
  }),
  columnHelper.accessor('offerInfo', {
    id: 'type',
    header: 'Offer Type',
    size: 120,
    cell: (info) => {
      const { offerType } = info.getValue() as OfferInfo || {};
      return getOfferTypeName(offerType as OfferType) || '-';
    },
  }),
  columnHelper.accessor('id', {
    id: 'id',
    header: 'ID',
    size: 50,
    cell: (info) => {
      return info.getValue() || '-';
    },
  }),
  columnHelper.display({
    id: 'name',
    header: 'Name',
    size: 120,
    meta: {
      extraClassName: classes.nameColEllipsis,
    },
    cell: ({ row }) => {
      const { offerInfo } = row.original || {};
      const { name } = offerInfo || {};
      return (
        name
          ? (
            <span
              onClick={() => onClickRow('', row.original)}
              className={classes.nameCol}
              onKeyDown={() => {}}
              role="button"
              tabIndex={0}
            >
              {name}
            </span>
          )
          : '-'
      );
    },
  }),
  columnHelper.accessor('providerInfo', {
    id: 'provider',
    header: 'Provider',
    size: 150,
    cell: (info) => {
      const { name } = info.getValue() as ProviderInfo || {};
      return name || '-';
    },
  }),
  columnHelper.accessor('slots', {
    id: 'price',
    header: 'Price',
    size: 70,
    cell: (info) => {
      const slots = info.getValue() as Array<TeeOfferSlot> || {};
      const holdSum = slots?.[0]?.usage.price;
      return holdSum ? <TooltipDeposit deposit={holdSum} tooltip={false} /> : '-';
    },
  }),
];
