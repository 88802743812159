import { memo, useCallback } from 'react';
import copy from 'copy-to-clipboard';
import { CopyToClipboardProps } from './types';
import { ButtonWithIcon } from '../ButtonWithIcon';

export const CopyToClipboard = memo<CopyToClipboardProps>(({
  text, onCopy: onCopyProp, ...props
}) => {
  const onCopy = useCallback(() => {
    if (text) {
      copy(text);
      onCopyProp?.(text);
    }
  }, [text, onCopyProp]);

  return <ButtonWithIcon {...props} onClick={onCopy} />;
});
