import { memo, FC } from 'react';
import { Box } from 'uikitv2/Box';
import { ConfigurationBlock } from './ConfigurationBlock';
import { ConfigurationProps } from './types';
import classes from './Configuration.module.scss';

export const Configuration: FC<ConfigurationProps> = memo(({ slots, options, title }) => {
  if (!slots?.length && !options?.length) return null;
  return (
    <Box direction="column">
      {!!title && <Box className={classes.title}>{title}</Box>}
      <Box className={classes.blocks}>
        {!!slots?.length && <ConfigurationBlock list={slots} className={classes.block} />}
        {!!options?.length && <ConfigurationBlock list={options} />}
      </Box>
    </Box>
  );
});
