'use client';

import { memo } from 'react';

import { Modal } from 'uikitv2/Modal';
import { useOfferModal } from 'hooks/useOfferModal';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { Offer } from './Offer';
import classes from './OfferModal.module.scss';

export const OfferModal = memo(() => {
  const theme = useAppSelector(themeSelector);
  const {
    offer, onClose, tab, onChangeTab,
  } = useOfferModal();

  return (
    <Modal
      show={!!offer?.offerId}
      onClose={onClose}
      dialogClassName={classes.dialog}
      bodyClassName={classes.body}
      contentClassName={classes.content}
      theme={theme}
    >
      {!!offer?.offerId && <Offer id={offer?.offerId} slotsFromQuery={offer?.slots} tab={tab} onChangeTab={onChangeTab} />}
    </Modal>
  );
});

export default OfferModal;
