import { Theme } from 'uikit/common/types';
import { AdderBtnProps } from '../AdderBtn/types';

export enum OfferTypeTextBtn {
  isNotEnabled = 'isNotEnabled',
  isOfferBase = 'isOfferBase',
  isOfferSoon = 'isOfferSoon',
  isOfferInactive = 'isOfferInactive',
}

export interface AdderBtnOfferProps extends AdderBtnProps {
  offerTextBtn?: OfferTypeTextBtn;
}

export interface GetDisabledViewProps {
  offerTextBtn?: OfferTypeTextBtn;
  theme?: Theme;
}