import { useMemo } from 'react';
import { useStorages } from 'hooks/storage/useStorages';
import { File } from 'hooks/files/types';
import { getStorageIdsFromFiles } from '../helpers';

export const useStoragesFromFiles = (files?: File[]) => {
  const storagesIds = useMemo(() => getStorageIdsFromFiles(files), [files]);
  const { data: storages, loading } = useStorages(!storagesIds?.length);
  return {
    loading,
    storages,
  };
};