import { memo } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { Theme } from 'uikitv2/common/types';
import { TabsLinkProps } from './types';
import classes from './TabsLink.module.scss';

export const TabsLink = memo(({
  href,
  label,
  className,
  disabled,
  theme = Theme.dark,
  onClick,
  dataTestId,
  query,
  prefetch = false,
}: TabsLinkProps) => {
  return (
    <Link
      onClick={onClick}
      href={{ pathname: href, query }}
      className={cn(classes.wrap, className, classes[theme], { [classes.disabled]: disabled })}
      data-testid={dataTestId}
      prefetch={prefetch}
    >
      {label}
    </Link>
  );
});
