import { Theme } from 'uikit/common/types';
import { GetDisabledViewProps, OfferTypeTextBtn } from './types';
import { DisabledViewProps } from '../AdderBtn/DisabledView/types';

export const offerTextBtnStructure = {
  [OfferTypeTextBtn.isNotEnabled]: {
    tooltipText: 'Offer disabled',
    text: 'Disabled',
    showIcon: true,
    dataTestId: 'button-disabled',
  },
  [OfferTypeTextBtn.isOfferBase]: {
    tooltipText: 'Base image is added automatically to primary solution',
    text: 'Base Image',
    dataTestId: 'button-base-image',
    showIcon: false,
  },
  [OfferTypeTextBtn.isOfferSoon]: {
    tooltipText: 'Offer is available only through CLI',
    text: 'CLI Only',
    dataTestId: 'button-cli-only',
    showIcon: false,
  },
  [OfferTypeTextBtn.isOfferInactive]: {
    tooltipText: 'Offer is inactive and cannot be ordered',
    text: 'Inactive',
    dataTestId: 'button-inactive',
    showIcon: false,
  },
};

export const getDisabledView = ({
  offerTextBtn, theme = Theme.dark,
}: GetDisabledViewProps): DisabledViewProps | null => {
  if (offerTextBtn === OfferTypeTextBtn.isNotEnabled) {
    return { ...offerTextBtnStructure[OfferTypeTextBtn.isNotEnabled], theme };
  }
  if (offerTextBtn === OfferTypeTextBtn.isOfferBase) {
    return { ...offerTextBtnStructure[OfferTypeTextBtn.isOfferBase], theme };
  }
  if (offerTextBtn === OfferTypeTextBtn.isOfferSoon) {
    return { ...offerTextBtnStructure[OfferTypeTextBtn.isOfferSoon], theme };
  }
  if (offerTextBtn === OfferTypeTextBtn.isOfferInactive) {
    return { ...offerTextBtnStructure[OfferTypeTextBtn.isOfferInactive], theme };
  }
  return null;
};
