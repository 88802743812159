import { memo, FC } from 'react';
import { BilletValue } from 'components/BilletValue';
import { Box } from 'uikitv2/Box';
import { ConfigurationBlockProps } from './types';
import classes from './ConfigurationBlock.module.scss';

export const ConfigurationBlock: FC<ConfigurationBlockProps> = memo(({ list, className }) => {
  return (
    <Box direction="column" className={className}>
      {list.map(({ label, value }, key) => (
        <BilletValue className={classes.item} key={`${label}-${key}`} label={label} value={value} />
      ))}
    </Box>
  );
});