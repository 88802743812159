import { useAuth } from 'hooks/auth/useAuth';
import { useLazySPFile, UseLazySPFile } from './useLazySPFile';
import { useLazySecretKeeperFile, UseLazySecretKeeperFile } from './useLazySecretKeeperFile';

export type UseLazyFile = UseLazySPFile | UseLazySecretKeeperFile;

export const useLazyFile = (): UseLazyFile => {
  const { isProviderSPProvider } = useAuth();
  const spProviderFile = useLazySPFile();
  const secretKeeperFile = useLazySecretKeeperFile();

  return isProviderSPProvider ? spProviderFile : secretKeeperFile;
};