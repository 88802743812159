import { forwardRef, memo } from 'react';
import cn from 'classnames';
import { Theme } from 'uikit/common/types';
import { PaperProps } from './types';
import classes from './Paper.module.scss';

export const Paper = memo(forwardRef<HTMLDivElement, PaperProps>(({
  className,
  children,
  onClick,
  onKeyDown = () => {},
  theme = Theme.dark,
  testId,
}, ref) => {
  return (
    <div
      ref={ref}
      className={cn(classes.content, classes[theme], className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      data-test-id={testId}
      role="textbox"
      tabIndex={0}
    >
      {children}
    </div>
  );
}));