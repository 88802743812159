'use client';

import { useCallback } from 'react';
import { useAppDispatch } from 'lib/hooks';
import { Slots } from 'lib/features/createOrderV2/types';
import { trackEvent } from 'lib/features/events/thunks';
import { OfferTabs } from 'components/OfferModal/Offer/Left/types';
import { Offer, TeeOffersAndSLots } from 'generated/types';
import { useOfferModal } from './useOfferModal';

export const useOnClickRow = () => {
  const { openModal: openModalOffer } = useOfferModal();
  const dispatch = useAppDispatch();
  const openModal = useCallback((offerId: string, slots: Slots, tab: OfferTabs) => {
    openModalOffer(offerId, slots, tab);
    dispatch(trackEvent({
      eventType: 'open_offer_description',
      property: { offerId },
    }));
  }, [dispatch, openModalOffer]);

  const onClickRow = useCallback((_: string, data: Offer | TeeOffersAndSLots) => {
    const offerId = (data as TeeOffersAndSLots)?.teeOffer?.id || (data as Offer)?.id;
    openModal(offerId, {}, OfferTabs.about);
  }, [openModal]);

  return { onClickRow, openModal };
};
