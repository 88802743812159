import { useMemo } from 'react';

import { useGetOffersQuery } from 'lib/features/offers';

const useGetOffers = (ids: string[]) => {
  const { data } = useGetOffersQuery({ filter: { ids }, pagination: { first: 10 } }, {
    skip: !ids.length,
  });

  return useMemo(() => (data?.result?.page?.edges
    ? data.result.page.edges.map(({ node }) => node as any)
    : []), [data]);
};

export default useGetOffers;
