import { useMemo } from 'react';
import { FormContent } from 'lib/features/createOrderV2/types';
import { useFiles } from 'hooks/files/useFiles/useFiles';
import { getFilesIds } from '../helpers';

export const useFilesFromFormContent = (formContent: FormContent) => {
  const filesIds = useMemo(() => getFilesIds(formContent), [formContent]);
  const { files, loading } = useFiles({ skip: !filesIds.length });
  return {
    loading,
    files,
  };
};