import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import cn from 'classnames';
import { LazyLoad } from 'uikit/LazyLoad';
import { Theme } from 'uikit/common/types';
import { genericMemo } from 'uikit/common/utils';
import { TableProps } from './types';
import classes from './Table.module.scss';

function Component<TData = any, TValue = any>({
  data,
  columns,
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  classNames = {},
  classNameContainer,
  onClickRow = () => {},
  theme = Theme.dark,
  getRowId,
  testId,
  state,
  showLoader = true,
}: TableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: !!process.env.NEXT_PUBLIC_DEBUG,
    getRowId,
    state,
  });
  const { rows } = table.getRowModel();

  return (
    <LazyLoad
      className={cn(classes.container, classNames?.wrap, classes[theme], classNameContainer)}
      fetchNextPage={fetchNextPage}
      hasNextPage={hasNextPage}
      loading={isFetchingNextPage}
      showLoader={showLoader}
      testId={testId}
    >
      <table className={cn(classes.table, classNames?.table)}>
        <thead className={cn(classes.thead, classNames?.thead)}>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const { ellipsis } = header.column.columnDef.meta || {};
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize() }}
                    className={cn(classes.th, { [classes.ellipsis]: ellipsis !== false }, classNames?.th)}
                  >
                    {header.isPlaceholder ? null : (
                      <>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </>
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {rows.map((row) => {
            const id = getRowId?.(row as TData, row.index) ?? row.id;
            return (
              <tr
                data-test-id={id}
                key={row.id}
                className={cn(classes.tr, classNames?.tr)}
                onClick={() => onClickRow(row.id, row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  const { ellipsis, extraClassName } = cell.column.columnDef.meta || {};
                  return (
                    <td
                      key={cell.id}
                      className={cn(classes.td, { [classes.ellipsis]: ellipsis !== false }, classNames?.td, extraClassName)}
                      style={{ width: cell.column.getSize() }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </LazyLoad>
  );
}

export const Table = genericMemo(Component);
export default genericMemo(Component);
