import { SyntheticEvent, useMemo } from 'react';
import { useAppSelector } from 'lib/hooks';
import { isMobileSelector } from 'lib/features/windowSize';
import { Tooltip } from 'uikitv2/Tooltip/Tooltip';
import { Box } from 'uikitv2/Box';
import { Icon } from 'uikitv2/Icon';
import { DisabledViewProps } from './types';
import classes from './DisabledView.module.scss';

export const DisabledView = ({
  tooltipText, text, theme, showIcon,
}: DisabledViewProps) => {
  const isMobile = useAppSelector(isMobileSelector);
  const buttonToolbarProps = useMemo(() => ({
    onClick: isMobile ? (e: SyntheticEvent) => e.stopPropagation() : undefined,
  }), [isMobile]);

  return (
    <Tooltip
      tooltip={tooltipText}
      theme={theme}
      delay={0}
      placement="top-end"
      classNamePopover={classes.popover}
      classNamePopoverText={classes.popoverText}
      buttonToolbarProps={buttonToolbarProps}
    >
      <Box alignItems="center">
        {showIcon && <Icon name="disabled" className={classes.iconDisabled} width={10} />}
        <span className={classes.text}>{text}</span>
      </Box>
    </Tooltip>
  );
};
