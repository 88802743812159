import { memo, FC, useCallback } from 'react';

import { Checkbox } from 'uikitv2/Checkbox';
import { Theme } from 'uikitv2/common/types';
import { LazyLoadCheckboxListProps, Value } from './types';
import { LazyLoad } from '../LazyLoad';
import classes from './LazyLoadCheckboxList.module.scss';

export const LazyLoadCheckboxList: FC<LazyLoadCheckboxListProps> = memo(({
  list, isMulti, values, onChange: onChangeProp, theme = Theme.dark, disabled = false, ...props
}) => {
  const onChange = useCallback((newValue: Value, checked: boolean) => {
    if (isMulti) {
      onChangeProp?.(
        checked ? [...new Set([...(values || []), newValue])] : (values || []).filter((value) => value !== newValue),
      );
    } else {
      onChangeProp?.(checked ? newValue : undefined);
    }
  }, [values, onChangeProp, isMulti]);
  return (
    <LazyLoad {...props}>
      {list.map(({ value, label }) => (
        <Checkbox
          key={`${value}`}
          classNameWrap={classes.item}
          classNameLabel={classes.label}
          label={label}
          checked={values?.includes?.(value)}
          onChange={(checked) => onChange(value, checked)}
          theme={theme}
          disabledInput={disabled}
          align="top"
        />
      ))}
    </LazyLoad>
  );
});