import { Box } from 'uikitv2/Box';
import { BilletValueProps } from './types';
import classes from './BilletValue.module.scss';

export const BilletValue = ({ label, value, className }: BilletValueProps) => {
  return (
    <Box className={className}>
      <span className={classes.label}>{`${label}:`}</span>
      &nbsp;
      <span>{value}</span>
    </Box>
  );
};
