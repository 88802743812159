'use client';

import cn from 'classnames';

import { genericMemo } from 'uikitv2/common/utils';
import { Theme } from 'uikitv2/common/types';
import { TabsProps } from './types';
import { TabsLink } from './TabsLink';
import { TabsBtn } from './TabsBtn';
import classes from './Tabs.module.scss';

function Component<TValue>({
  list,
  onClick,
  active,
  classNameItem,
  classNameTabItem,
  classNameWrap,
  alignLeft = false,
  theme = Theme.dark,
  dataTestId = 'tabs',
  hasUnderline = true,
}: TabsProps<TValue>) {
  return (
    <ul className={cn(classes.wrap, classNameWrap)} data-testid={dataTestId}>
      {list.map(({
        label, value, href, disabled,
      }, idx) => {
        const className = cn(
          classes.default,
          classNameItem,
          { [classes.active]: active === value },
          { [classes.aligntLeft]: alignLeft && idx === 0 },
        );
        return (
          <li
            key={value as unknown as string}
            className={cn(classes.item, classNameTabItem)}
            data-testid={`li-${value}`}
          >
            {
              href
                ? (
                  <TabsLink
                    onClick={() => onClick?.(value)}
                    label={label}
                    href={href}
                    className={className}
                    disabled={disabled}
                    theme={theme}
                    dataTestId={`href-${value}`}
                  />
                )
                : (
                  <TabsBtn
                    label={label}
                    className={className}
                    onClick={() => onClick?.(value)}
                    disabled={disabled}
                    theme={theme}
                    active={active === value}
                    dataTestId={`button-${value}`}
                    hasUnderline={hasUnderline}
                  />
                )
            }
          </li>
        );
      })}
    </ul>
  );
}

export const Tabs = genericMemo(Component);
