import { File, GetFilesProps } from 'lib/features/secretKeeper/types';
import { useAuth } from 'hooks/auth/useAuth';
import { useGetFilesQuery } from 'lib/features/secretKeeper/api';

export interface UseSecretKeeperFiles {
  files?: File[];
  loading: boolean;
  refetch: () => void;
}

export interface UseSecretKeeperFilesProps {
  skip?: boolean;
  body?: GetFilesProps | null;
}

export const useSecretKeeperFiles = (props?: UseSecretKeeperFilesProps): UseSecretKeeperFiles => {
  const { skip, body = null } = props || {};
  const { isUserConnected, isProviderSecretKeeper } = useAuth();
  const response = useGetFilesQuery(
    body,
    { skip: !isUserConnected || !isProviderSecretKeeper || skip, refetchOnMountOrArgChange: true },
  );

  return {
    files: response.data,
    loading: response.isLoading || response.isFetching,
    refetch: response.refetch,
  };
};