import { memo, FC } from 'react';
import cn from 'classnames';

import { Theme } from 'uikit/common/types';
import { TabsBtnProps } from './types';
import classes from './TabsBtn.module.scss';

export const TabsBtn: FC<TabsBtnProps> = memo(({
  disabled, onClick, className, label, theme = Theme.dark, dataTestId, active, hasUnderline,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={cn(
        classes.wrap,
        { [classes.underline]: hasUnderline },
        className,
        classes[theme],
        { [classes.disabled]: disabled },
      )}
      data-testid={dataTestId}
      data-active={active}
    >
      <span>{label}</span>
    </button>
  );
});
