import React, {
  memo,
  useRef,
  FC,
} from 'react';
import cn from 'classnames';

import { Box } from 'uikit/Box';
import { useIntersectionObserver } from 'uikit/common/hooks/useIntersectionObserver';
import { Spinner } from 'uikit/Spinner';
import { LazyLoadProps } from './types';
import classes from './LazyLoad.module.scss';

export const LazyLoad: FC<LazyLoadProps> = memo(({
  hasNextPage,
  fetchNextPage,
  loading,
  showLoader = false,
  children,
  className,
  testId,
}) => {
  const triggerRef = useRef(null);
  useIntersectionObserver(
    triggerRef,
    {},
    () => hasNextPage && !loading && fetchNextPage?.(),
  );

  return (
    <Box direction="column" className={cn(classes.wrap, className)} data-testid={testId}>
      {children}
      {showLoader && loading && (
        <Box alignItems="center" justifyContent="center" className={classes.spinnerWrap}>
          <Spinner />
          <span className={classes.spinnerText}>loading...</span>
        </Box>
      )}
      <div ref={triggerRef} className={cn(classes.trigger)} />
    </Box>
  );
});
