import {
  useLazyPostFileQuery, useLazyPatchFileQuery, useLazyDeleteFileQuery, useLazyGetFileQuery,
} from 'lib/features/secretKeeper/api';

export interface UseLazySecretKeeperFile {
  createFile: ReturnType<typeof useLazyPostFileQuery>[0];
  updateFile: ReturnType<typeof useLazyPatchFileQuery>[0];
  deleteFile: ReturnType<typeof useLazyDeleteFileQuery>[0];
  getFile: ReturnType<typeof useLazyGetFileQuery>[0];
}

export const useLazySecretKeeperFile = (): UseLazySecretKeeperFile => {
  const [createFile] = useLazyPostFileQuery();
  const [updateFile] = useLazyPatchFileQuery();
  const [deleteFile] = useLazyDeleteFileQuery();
  const [getFile] = useLazyGetFileQuery();

  return {
    createFile,
    updateFile,
    deleteFile,
    getFile,
  };
};