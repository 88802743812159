import { useMemo } from 'react';
import { getExtendedContent } from 'lib/features/createOrderV2/helpers';
import { FormContent } from 'lib/features/createOrderV2/types';
import { useOffersAndTeeOffersFromFormContent } from './useOffersAndTeeOffersFromFormContent';
import { useFilesFromFormContent } from './useFilesFromFormContent';
import { useStoragesFromFiles } from './useStoragesFromFiles';

export const useExtendedFormContent = (formContent: FormContent) => {
  const { loading: loadingOfferAndTeeOffers, offers, teeOffers } = useOffersAndTeeOffersFromFormContent(formContent);
  const { loading: loadingFiles, files } = useFilesFromFormContent(formContent);
  const { loading: loadingStorages, storages } = useStoragesFromFiles(files);
  const extendedFormContent = useMemo(
    () => getExtendedContent({
      formContent, offers, teeOffers, files, storages,
    }),
    [formContent, offers, teeOffers, files, storages],
  );
  return {
    extendedFormContent,
    loading: loadingFiles || loadingOfferAndTeeOffers || loadingStorages,
  };
};