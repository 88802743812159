import { memo } from 'react';
import cn from 'classnames';

import { TableHeaderProps } from './types';
import classes from './TableHeader.module.scss';

export const TableHeader = memo<TableHeaderProps>(({ children, classNameWrap }) => {
  return (
    <div className={cn(classes.content, classNameWrap)}>
      {children}
    </div>
  );
});
