import classes from './Right.module.scss';

export const labelLine = 'Offer added to Order';

export const getValue = (v: string | string[]) => {
  if (Array.isArray(v)) {
    return v.map((el, idx) => (
      <span className={classes['price-text']} key={idx}>{el}</span>
    ))
  }
  return <span className={classes['price-text']}>{v}</span>
}