import { memo, FC, useMemo } from 'react';
import { useAppSelector } from 'lib/hooks';
import { themeSelector } from 'lib/features/theme';
import { AdderBtn } from '../AdderBtn';
import { DisabledView } from '../AdderBtn/DisabledView';
import { AdderBtnOfferProps } from './types';
import { getDisabledView } from './helpers';

export const AdderBtnOffer: FC<AdderBtnOfferProps> = memo(({
  offerTextBtn, ...props
}) => {
  const theme = useAppSelector(themeSelector);
  const disabledView = useMemo(() => getDisabledView({
    offerTextBtn, theme,
  }), [theme, offerTextBtn]);

  if (disabledView) {
    return (
      <DisabledView {...disabledView} />
    );
  }
  return <AdderBtn {...props} />;
});

export default AdderBtnOffer;