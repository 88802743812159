import { Storage } from 'lib/features/secretKeeper/types';
import { useAuth } from 'hooks/auth/useAuth';
import { useGetStoragesQuery } from 'lib/features/secretKeeper/api';

export interface UseSecretKeeperStorages {
  data?: Storage[];
  loading: boolean;
  refetch: () => void;
}

export const useSecretKeeperStorages = (skip?: boolean): UseSecretKeeperStorages => {
  const { isUserConnected, isProviderSecretKeeper } = useAuth();
  const storageResponse = useGetStoragesQuery(
    null,
    { skip: !isUserConnected || !isProviderSecretKeeper || skip, refetchOnMountOrArgChange: true },
  );

  return {
    data: storageResponse?.data,
    loading: storageResponse.isLoading,
    refetch: storageResponse.refetch,
  };
};