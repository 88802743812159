import { useMemo } from 'react';

import { useAppSelector } from 'lib/hooks';
import { Box } from 'uikit/Box';
import { Table } from 'uikit/Table';
import { themeSelector } from 'lib/features/theme';
import { useOnClickRow } from 'hooks/useOnClickRow';
import useGetOffers from './useGetOffers';
import { RestrictedProps } from './types';
import { getColumns } from './columns';
import { title } from './helpers';
import classes from './Restricted.module.scss';

export const Restricted = ({ ids }: RestrictedProps) => {
  const theme = useAppSelector(themeSelector);
  const { onClickRow } = useOnClickRow();
  const data = useGetOffers(ids);
  const columns = useMemo(() => getColumns(onClickRow), [onClickRow]);
  const classNames = useMemo(() => ({
    wrap: classes.tableContainer,
    thead: classes.thead,
    td: classes.td,
    th: classes.th,
  }), []);

  return (
    <Box direction="column" className={classes[theme]}>
      <div className={classes.title}>{title}</div>
      <Table
        columns={columns}
        data={data}
        classNames={classNames}
        theme={theme}
      />
    </Box>
  );
};
