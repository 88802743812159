import { getBaseQuery } from 'connectors/sp';
import { createApi } from '@reduxjs/toolkit/query/react';
import {
  TeeOffersQuery,
  TeeOffersQueryVariables,
  TeeOffersDocument,
  TeeOfferQuery,
  TeeOfferQueryVariables,
  TeeOfferDocument,
  GetMatchingTeeSlotsDocument,
  GetMatchingTeeSlotsQuery,
  GetMatchingTeeSlotsQueryVariables,
  GetMinimalConfigurationDocument,
  GetMinimalConfigurationQuery,
  GetMinimalConfigurationQueryVariables,
} from 'generated/schemas/teeOffers';

export const teeOffers = createApi({
  reducerPath: 'teeOffers',
  baseQuery: getBaseQuery(),
  endpoints: (build) => ({
    getTeeOffers: build.query<TeeOffersQuery, TeeOffersQueryVariables>({
      query: (variables) => ({ document: TeeOffersDocument, variables }),
    }),
    getTeeOffer: build.query<TeeOfferQuery, TeeOfferQueryVariables>({
      query: (variables) => ({ document: TeeOfferDocument, variables }),
    }),
    getMatchingTeeSlots: build.query<GetMatchingTeeSlotsQuery, GetMatchingTeeSlotsQueryVariables>({
      query: (variables) => ({ document: GetMatchingTeeSlotsDocument, variables }),
    }),
    getMinimalConfiguration: build.query<GetMinimalConfigurationQuery, GetMinimalConfigurationQueryVariables>({
      query: (variables) => ({ document: GetMinimalConfigurationDocument, variables }),
    }),
  }),
});

export const {
  useGetTeeOffersQuery,
  useLazyGetTeeOffersQuery,
  useGetTeeOfferQuery,
  useLazyGetTeeOfferQuery,
  useGetMatchingTeeSlotsQuery,
  useLazyGetMatchingTeeSlotsQuery,
  useGetMinimalConfigurationQuery,
  useLazyGetMinimalConfigurationQuery,
} = teeOffers;
